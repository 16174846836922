export const environment = {
  production: false,
  currency: '',

  // PARAMETROS ADMIN - La Veneciana
  firebase: {
    apiKey: "AIzaSyB7qqrv6Fvsu-Qod0Xal7ARCbvV_i9A1HE",
    authDomain: "ianos-app-mx-la-veneciana-pro.firebaseapp.com",
    projectId: "ianos-app-mx-la-veneciana-pro",
    storageBucket: "ianos-app-mx-la-veneciana-pro.appspot.com",
    messagingSenderId: "723971402098",
    appId: "1:723971402098:web:23ad10987cb887e17755eb",
    measurementId: "G-VGBSLPLYCR"
  },
  googleMapsKey: 'AIzaSyC0N_UyDvlLly4CbXV3zOrGwgvmZIIWl_4',
  urlBase: 'https://us-central1-ianos-app-mx-la-veneciana-pro.cloudfunctions.net',
  apikey: 'c50be09df5f-e1b028172aa40-4ae8-a2e75ea1',

};
